import React from "react";
import Layout from "../components/layout";
import Client from "../components/client";
import SEO from "../components/seo";

const Imprint = () => (
  <Layout>
    <SEO title="Imprint" />
    <Client />
    <div className="error-page-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <h1>Impressum</h1>
              <p>
                Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
                Offenlegungspflicht laut §25 Mediengesetz.
              </p>
              <p>
                <strong>Matthias Neumayer | Slimline Film GmbH Gesellschaft mit beschränkter Haftung</strong>
              </p>
              <p>
                <strong> GF: Mag. Matthias Neumayer, BA</strong>
              </p>
              <p>UID-Nr: ATU62682369</p>
              <p> FN: 195782 s</p>
              <p>FB-Gericht: Handelsgericht Wien</p>
              <p>Sitz: 1030 Wien, Hörnesgasse 4</p>
              <p>E-Mail: hello@matthiasneumayer.com</p>
              <p>E-Mail: office@brandvisions.at</p>
              <p>
                <strong>Allgemeine Hinweise:</strong>
                Das Kopieren der Inhalte dieser Webseite für kommerzielle Zwecke, das Verarbeiten, Verändern oder das
                Zugänglichmachen für Dritte ist nicht gestattet. Der Inhalt dieser Seite kann jederzeit durch die
                Berechtigten geändert und gelöscht werden, ohne dass daraus rechtliche Konsequenzen abgeleitet werden
                können. Die Seite ist urheberrechtlich geschützt und ist die Vervielfältigung nur im Falle des technisch
                notwendigen Rahmens, nicht aber zu wirtschaftlichen Zwecken oder zum Zwecke der dauerhaften
                Vervielfältigung gestattet.
              </p>
              <p>
                <strong>Datenschutz:</strong>
                Wir verwenden auf unserer Seite sog. Cookies zum Wiedererkennen mehrfacher Nutzung unseres Angebots,
                durch denselben Nutzer/Internetanschlussinhaber. Cookies sind kleine Textdateien, die Ihr
                Internet-Browser auf Ihrem Rechner ablegt und speichert. Sie dienen dazu, unseren Internetauftritt und
                unsere Angebote zu optimieren. Es handelt sich dabei zumeist um sog. „Session-Cookies“, die nach dem
                Ende Ihres Besuches wieder gelöscht werden. Teilweise geben diese Cookies jedoch Informationen ab, um
                Sie automatisch wieder zu erkennen. Diese Wiedererkennung erfolgt aufgrund der in den Cookies
                gespeicherten IP-Adresse. Die so erlangten Informationen dienen dazu, unsere Angebote zu optimieren und
                Ihnen einen leichteren Zugang auf unsere Seite zu ermöglichen. Sie können die Installation der Cookies
                durch eine entsprechende Einstellung Ihres Browsers verhindern; wir weisen Sie jedoch darauf hin, dass
                Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen
                können.
              </p>
              <p>
                <strong>Serverdaten</strong>
                Aus technischen Gründen werden u.a. folgende Daten, die Ihr Internet-Browser an uns bzw. an unseren
                Webspace-Provider übermittelt, erfasst (sogenannte Serverlogfiles):
              </p>
              <p>
                – Browsertyp und -version – verwendetes Betriebssystem – Webseite, von der aus Sie uns besuchen
                (Referrer URL) – Webseite, die Sie besuchen – Datum und Uhrzeit Ihres Zugriffs – Ihre Internet Protokoll
                (IP)-Adresse.
              </p>
              <p>
                Diese anonymen Daten werden getrennt von Ihren eventuell angegebenen personenbezogenen Daten gespeichert
                und lassen so keine Rückschlüsse auf eine bestimmte Person zu. Sie werden zu statistischen Zwecken
                ausgewertet, um unseren Internetauftritt und unsere Angebote optimieren zu können.
              </p>
              <p>
                <strong> Sie haben das Recht:</strong>
                gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies
                hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft
                nicht mehr fortführen dürfen; gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
                personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die
                Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
                offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
                Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die
                Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
                automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu
                deren Einzelheiten verlangen; gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
                Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen; gemäß Art. 17 DSGVO
                die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
                Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
                rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen erforderlich ist; gemäß Art. 18 DSGVO die Einschränkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen
                bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten
                nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt
                haben; gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
                anderen Verantwortlichen zu verlangen und gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes
                oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.
              </p>
              <strong>Widerspruchsrecht</strong>
              Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1
              lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
              Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
              besonderen Situation ergeben. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail
              an office@brandvisions.at Aktualität und Änderung dieser Datenschutzerklärung Diese Datenschutzerklärung ist
              aktuell gültig und hat den Stand Mai 2018. Durch die Weiterentwicklung unserer Website und Angebote
              darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig
              werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf
              der Website von Ihnen abgerufen und ausgedruckt werden.
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Imprint;
